<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('li_step.dam_report') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Division">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="division_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.division') }}
                                </template>
                                <b-form-select
                                id="division_id"
                                plain
                                v-model="formData.division_id"
                                :options="divisionList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="disabledCheck()"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="District">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="district_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.district') }}
                                </template>
                                <b-form-select
                                plain
                                v-model="formData.district_id"
                                :options="districtList"
                                id="district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="disabledCheck()"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Subdistrict">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="upazila_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.upazila') }}
                                </template>
                                <b-form-select
                                plain
                                v-model="formData.upazila_id"
                                :options="upazilaList"
                                id="upazila_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Market">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="market_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('crop_price_config.market') }}
                            </template>
                            <b-form-select
                              plain
                              v-model="formData.market_id"
                              :options="marketList"
                              id="market_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Business Class">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="business_class"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('li_step.business_class') }}
                            </template>
                            <b-form-select
                              plain
                              v-model="formData.business_class"
                              :options="businessClassList"
                              id="business_class"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Type">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="type"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('globalTrans.type') }}
                            </template>
                            <b-form-select
                              plain
                              v-model="formData.type"
                              :options="dataType"
                              id="type"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('globalTrans.from_date')"
                          label-for="from_date"
                        >
                          <b-form-input
                            id="datepicker"
                            v-model="formData.from_date"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                          <b-form-group
                           class="row"
                            label-cols-sm="4"
                            :label="$t('globalTrans.to_date')"
                            label-for="to_date"
                          >
                          <b-form-input
                            id="datepicker"
                            v-model="formData.to_date"
                            ></b-form-input>
                          </b-form-group>
                      </b-col>
                      <b-col lg="6" sm="12">
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="12" sm="12" style="text-align:right" class="float-right mb-1 dam-scroll-main">
                        <b-dropdown class="column-dam" split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                          <b-dropdown-form>
                            <div
                              class="form-group dam-grp"
                              v-for="(field, index) in labelData"
                              :key="index"
                              >
                                <b-form-checkbox
                                  :id="'checkbox-' + field.labels"
                                  v-model="field.show"
                                  :name="'checkbox-' + field.labels"
                                  value=1
                                  unchecked-value=0
                                  class="dam-drop"
                                >
                                  {{ $t(field.labels)  }}
                                </b-form-checkbox>
                            </div>
                          </b-dropdown-form>
                        </b-dropdown>
                      </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12">
            <b-overlay :show="loading">
              <iq-card v-if='datas.length'>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('li_step.dam_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new mr-2" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                          </b-button>
                            <export-excel
                              class="btn btn_add_new"
                              :data="dataCustomizeExcel()"
                              :title="headerValue"
                              :fields= "json_fields"
                              worksheet="Dam Report"
                              :before-generate = "headerDataExcel"
                              name="dam.xls">
                              <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                            </export-excel>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :baseUrl="licenseRegistrationServiceBaseUrl" :url="'/configuration/report-heading/detail'" :org-id="13">
                                        {{ $t('li_step.dam_report') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <b-row class="pl-3 pr-3 mb-3">
                                        <b-col md="4" class="text-left">
                                          {{ $t('globalTrans.division') }}: <strong>{{ formData.division_id ? ($i18n.locale === 'en' ? formData.division_name_en : formData.division_name_bn) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="4" class="text-left">
                                          {{ $t('globalTrans.district') }}: <strong>{{ formData.district_id  ?  ($i18n.locale === 'en' ? formData.district_name_en : formData.district_name_bn) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="4" class="text-right">
                                          {{ $t('globalTrans.upazila') }}: <strong>{{ formData.upazila_id  ?  ($i18n.locale === 'en' ? formData.upazila_name_en : formData.upazila_name_bn) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="4" class="text-left">
                                          {{ $t('crop_price_config.market') }}: <strong>{{ formData.market_id ? ($i18n.locale === 'en' ? formData.market_name_en : formData.market_name_bn) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="4" class="text-left">
                                          {{ $t('li_step.business_class') }}: <strong>{{ formData.business_class ? ($i18n.locale === 'en' ? formData.business_name_en : formData.business_name_bn) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="4" class="text-right">
                                          <slot v-if="formData.type">
                                            {{ $t('globalTrans.type') }}: <strong><slot v-if="formData.type === 1">{{ $t('globalTrans.new') }}</slot><slot v-else>{{ $t('globalTrans.renew') }}</slot></strong>
                                          </slot>
                                          <slot v-else>
                                            {{ $t('globalTrans.type') }}: <strong>{{ $t('globalTrans.all') }}</strong>
                                          </slot>
                                        </b-col>
                                        <b-col md="4">
                                          <slot v-if="formData.from_date">
                                            {{ $t('globalTrans.from_date') }}: <strong>{{ formData.from_date | dateFormat }}</strong>
                                          </slot>
                                          <slot v-else>
                                            {{ $t('globalTrans.from_date') }}: <strong>{{ $t('globalTrans.all') }}</strong>
                                          </slot>
                                        </b-col>
                                        <b-col md="4">
                                          <slot v-if="formData.to_date">
                                            {{ $t('globalTrans.to_date') }}: <strong>{{ formData.to_date | dateFormat }}</strong>
                                          </slot>
                                          <slot v-else>
                                            {{ $t('globalTrans.to_date') }}: <strong>{{ $t('globalTrans.all') }}</strong>
                                          </slot>
                                        </b-col>
                                        <b-col md="4" class="text-right">
                                          {{ $t('globalTrans.amount') }}: <strong>{{ $n(amount) }}</strong>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <div class="tableFixHead col-12 scrolled-td-table-wrapper">
                                        <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                                          <b-table head-variant="primary" class="tg" :items="datas" :sticky-header="stickyHeader" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                                            <template v-slot:cell(index)="data">
                                              {{ $n(data.index + 1) }}
                                            </template>
                                            <template v-slot:cell(amount)="data">
                                              {{ $n(data.item.amount) }}
                                            </template>
                                            <template v-slot:cell(vat)="data">
                                              {{ $n(data.item.vat) }}
                                            </template>
                                            <template v-slot:cell(total)="data">
                                              {{ $n(parseInt(data.item.vat) + parseInt(data.item.amount)) }}
                                            </template>
                                            <template v-slot:cell(issue_date)="data">
                                              <slot v-if="data.item.issue_date">{{ data.item.issue_date|dateFormat }}</slot>
                                            </template>
                                            <template v-slot:cell(expire_date)="data">
                                              <slot v-if="data.item.expire_date">{{ data.item.expire_date|dateFormat }}</slot>
                                            </template>
                                            <template v-slot:cell(type)="data">
                                              <span class="badge pay-status badge-success w-100 text-white font-weight-bold" v-if="data.item.type === 2">
                                                {{ $t('globalTrans.renew') }}
                                              </span>
                                              <span class="badge pay-status badge-primary w-100 text-white font-weight-bold" v-if="data.item.type === 1">
                                                {{ $t('globalTrans.new') }}
                                              </span>
                                            </template>
                                            <template v-slot:cell(division_5731)="data">
                                              {{ getDivision(data.item.division_5731) }}
                                            </template>
                                            <template v-slot:cell(district_1865)="data">
                                              {{ getDistrict(data.item.district_1865) }}
                                            </template>
                                            <template v-slot:cell(upazilla__4303)="data">
                                              {{ getUpazila(data.item.upazilla__4303) }}
                                            </template>
                                            <template v-slot:cell(division_7828)="data">
                                              {{ getDivision(data.item.division_7828) }}
                                            </template>
                                            <template v-slot:cell(district_6214)="data">
                                              {{ getDistrict(data.item.district_6214) }}
                                            </template>
                                            <template v-slot:cell(upazilla_4339)="data">
                                              {{ getUpazila(data.item.upazilla_4339) }}
                                            </template>
                                            <template v-slot:cell(division_6431)="data">
                                              {{ getDivision(data.item.division_6431) }}
                                            </template>
                                            <template v-slot:cell(district_6510)="data">
                                              {{ getDistrict(data.item.district_6510) }}
                                            </template>
                                            <template v-slot:cell(upazilla_2082)="data">
                                              {{ getUpazila(data.item.upazilla_2082) }}
                                            </template>
                                            <template v-slot:cell(business_c_2676)="data">
                                              {{ getBusinessClass(data.item.business_c_2676) }}
                                            </template>
                                            <template v-slot:cell(fee_name)="data">
                                              {{ getBusinessClass(data.item.business_c_2676) }}
                                            </template>
                                            <template v-slot:cell(name_of_no_2818)="data">
                                              {{ getMarketDirectory(data.item.name_of_no_2818) }}
                                            </template>
                                            <template v-slot:cell(product_de_4058)="data">
                                              {{ getProduct(data.item.product_de_4058) }}
                                            </template>
                                            <template v-slot:cell(product_ve_4186)="data">
                                              {{ getProductType(data.item.product_ve_4186) }}
                                            </template>
                                            <template v-slot:cell(mobile_num_5699)="data">
                                              {{ mobileNoSet(data.item.mobile_num_5699,$i18n.locale) }}
                                            </template>
                                            <template v-slot:cell(national_i_6977)="data">
                                              {{ nidNoSet(data.item.national_i_6977,$i18n.locale) }}
                                            </template>
                                            <template v-slot:cell(challan_no_date)="data">
                                              <div>{{ challanCheck(data.item.payment, 'challan') }}</div>
                                              <div>{{ challanCheck(data.item.payment, 'date') }}</div>
                                            </template>
                                            <template v-slot:cell(bank_name_and_branch)="data">
                                              <div>{{ bankBranchSet(data.item.payment) }}</div>
                                            </template>
                                            <template v-slot:cell(status)="data">
                                              {{ getStepName(data.item.current_step) + ' ' + ServiceLastStepCheck(data.item.service_id, data.item.current_step, data.item.status, data.item.generate_id, data.item.doc_verified, data.item.type) }}
                                            </template>
                                          </b-table>
                                        </slot>
                                      </div>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
              <div class="panel-body text-center mt-3" v-else>
                <h6 class="text-danger" v-if="!firstTime">{{ $t('globalTrans.noDataFound') }}</h6>
              </div>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<style >
.tableFixHead th div {
  color: white;
}
.tableFixHead  th {
  background: #609513;
  /* color: var(--iq-white); */
}
.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}

.first-col {
  width: 93px;
  min-width: 93px;
  max-width: 93px;
  left: 0px;
  background-color: white;
}

.second-col {
  width: 93px;
  min-width: 140px;
  max-width: 140px;
  left: 93px;
  background-color: white;
}
.third-col {
  width: 90px;
  min-width: 140px;
  max-width: 140px;
  left: 233px;
  background-color: white;
}
.underzindex {
  z-index: 3 !important;
}
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
  top: 0;
}
.topzindex {
  z-index: 5 !important;
}
.rightzindex {
  z-index: 4 !important;
}
.dam-drop.custom-control.custom-checkbox {
  white-space: nowrap;
}
.form-group.dam-grp {
  margin: 0px !important;
  font-size: 12px;
}
.dam-scroll-main ul.dropdown-menu.show {
  overflow: scroll !important;
  height: 500px !important;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { damReport, damReportConf, reportHeadingList } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadLrcpnOn.vue'
import ExportPdf from './export_pdf'
import excel from 'vue-excel-export'
import Vue from 'vue'
import Store from '@/store'
import { dateFormat } from '@/Utils/fliter'
Vue.use(excel)

export default {
  props: [],
  components: {
    ValidationObserver,
    ValidationProvider,
    ListReportHead
  },
  created () {
    this.setOrgAndOffice()
    this.excelInputChange()
    this.configData()
  },
  data () {
    return {
      noCollapse: false,
      stickyHeader: true,
      HeaderExcel: [],
      amount: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        division_name_en: '',
        division_name_bn: '',
        district_name_en: '',
        district_name_bn: '',
        upazila_name_bn: '',
        upazila_name_en: '',
        division_id: 0,
        district_id: 0,
        upazila_id: 0,
        market_id: 0,
        office_id: 0,
        type: 0,
        business_class: 0,
        business_class_key: null,
        from_date: '',
        to_date: ''
      },
      currentDate: '',
      listItemofyerterday: '',
      districtList: [],
      upazilaList: [],
      marketList: [],
      businessClass: [],
      licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      headerExcelDefault: {
        orgName: null,
        orgNameBn: null,
        address: null,
        address_bn: null
      },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', excelKey: 'index', order: 1, thStyle: { width: '10%' } },
        { labels: 'globalTrans.application_id', class: 'text-left', sortable: true, stickyColumn: true, show: '1', excelKey: 'ex_application_id', order: 2, thStyle: { width: '20%' } },
        { labels: 'globalTrans.applicants_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', excelKey: 'ex_applicant__1807', order: 3, thStyle: { width: '20%' } },
        { labels: 'li_step.business_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', excelKey: 'ex_business_name', order: 4, thStyle: { width: '20%' } },
        { labels: 'li_step.issue_date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', excelKey: 'ex_issue_date', order: 5, thStyle: { width: '20%' } },
        { labels: 'externalLrcpn.expired_date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', excelKey: 'ex_expire_date', order: 6, thStyle: { width: '20%' } },
        { labels: 'fee_name.fee_name', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_fee_name', order: 7, thStyle: { width: '20%' } },
        { labels: 'globalTrans.amount', class: 'text-left', sortable: true, stickyColumn: true, show: '1', excelKey: 'ex_amount', order: 8, thStyle: { width: '20%' } },
        { labels: 'globalTrans.vat', class: 'text-left', sortable: true, stickyColumn: true, show: '1', excelKey: 'ex_vat', order: 9, thStyle: { width: '20%' } },
        { labels: 'globalTrans.total', class: 'text-left', sortable: true, stickyColumn: true, show: '1', excelKey: 'ex_total', order: 10, thStyle: { width: '20%' } },
        { labels: 'externalLrcpn.type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', excelKey: 'ex_type', order: 11, thStyle: { width: '20%' } },
        { labels: 'globalTrans.spouse_name', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_fathers_na_6391', order: 12, thStyle: { width: '25%' } },
        { labels: 'globalTrans.mother_name', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_mothers_na_4511', order: 13, thStyle: { width: '20%' } },
        { labels: 'globalTrans.mobile', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_mobile_num_5699', order: 14, thStyle: { width: '20%' } },
        { labels: 'globalTrans.nid', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_national_i_6977', order: 15, thStyle: { width: '20%' } },
        { labels: 'globalTrans.tin', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_tin_number_7111', order: 16, thStyle: { width: '20%' } },
        { labels: 'globalTrans.email', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_email_6489', order: 17, thStyle: { width: '20%' } },
        { labels: 'li_step.present_division', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_division_5731', order: 18, thStyle: { width: '20%' } },
        { labels: 'li_step.present_district', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_district_1865', order: 19, thStyle: { width: '20%' } },
        { labels: 'li_step.present_upazila', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_upazilla__4303', order: 20, thStyle: { width: '20%' } },
        { labels: 'pusti_mapping.present_address', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_present_ad_5362', order: 21, thStyle: { width: '20%' } },
        { labels: 'li_step.permanent_division', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_division_7828', order: 22, thStyle: { width: '20%' } },
        { labels: 'li_step.permanent_district', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_district_6214', order: 23, thStyle: { width: '20%' } },
        { labels: 'li_step.permanent_upazila', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_upazilla_4339', order: 24, thStyle: { width: '20%' } },
        { labels: 'li_step.permanent_address', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_permanent__5521', order: 25, thStyle: { width: '20%' } },
        { labels: 'li_step.business_division', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_division_6431', order: 26, thStyle: { width: '20%' } },
        { labels: 'li_step.business_district', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_district_6510', order: 27, thStyle: { width: '20%' } },
        { labels: 'li_step.business_upazila', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_upazilla_2082', order: 28, thStyle: { width: '20%' } },
        { labels: 'li_step.business_address', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_addresseng_3160', order: 29, thStyle: { width: '20%' } },
        { labels: 'li_step.business_class', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_business_c_2676', order: 30, thStyle: { width: '20%' } },
        { labels: 'crop_price_config.market', class: 'text-left', sortable: true, stickyColumn: true, show: '1', excelKey: 'ex_name_of_no_2818', order: 31, thStyle: { width: '20%' } },
        { labels: 'dam-config.agri_type', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_product_ve_4186', order: 32, thStyle: { width: '20%' } },
        { labels: 'dam-config.agri_product', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_product_de_4058', order: 33, thStyle: { width: '20%' } },
        { labels: 'li_step.challan_no_date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', excelKey: 'challan_no_date', order: 34, thStyle: { width: '20%' } },
        { labels: 'li_step.bank_name_and_branch', class: 'text-left', sortable: true, stickyColumn: true, show: '1', excelKey: 'bank_name_and_branch', order: 35, thStyle: { width: '20%' } },
        { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '0', excelKey: 'ex_status', order: 36, thStyle: { width: '10%' } }
        // { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 5, thStyle: { width: '15%' } }
      ],
      datas: [],
      excelColumnBn: {},
      excelColumn: {},
      firstTime: true,
      showData: false
    }
  },
  mounted () {
    core.index()
    flatpickr('#datepicker', {})
    this.headerDataExcel()
  },
  computed: {
    dataType () {
      const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'New' : 'নতুন' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Reneiw' : 'রিনিউ' }
      ]
      return userList
    },
    businessClassList: function () {
      const listObject = this.businessClass
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.type_name, text: obj.type_name_bn }
        } else {
          return { value: obj.type_name, text: obj.type_name }
        }
      })
      return tmpList
    },
    json_fields: function () {
      return this.$i18n.locale === 'bn' ? this.excelColumnBn : this.excelColumn
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'applicant__1497' },
          { key: 'business_o_1113' },
          { key: 'issue_date' },
          { key: 'expire_date' },
          { key: 'fee_name' },
          { key: 'amount' },
          { key: 'vat' },
          { key: 'total' },
          { key: 'type' },
          { key: 'fathers_na_9299' },
          { key: 'mothers_na_5069' },
          { key: 'mobile_num_5699' },
          { key: 'national_i_6977' },
          { key: 'tin_number_7111' },
          { key: 'email_6489' },
          { key: 'division_5731' },
          { key: 'district_1865' },
          { key: 'upazilla__4303' },
          { key: 'present_ad_5848' },
          { key: 'division_7828' },
          { key: 'district_6214' },
          { key: 'upazilla_4339' },
          { key: 'permanent__7835' },
          { key: 'division_6431' },
          { key: 'district_6510' },
          { key: 'upazilla_2082' },
          { key: 'addressban_1958' },
          { key: 'business_c_2676' },
          { key: 'name_of_no_2818' },
          { key: 'product_ve_4186' },
          { key: 'product_de_4058' },
          { key: 'challan_no_date' },
          { key: 'bank_name_and_branch' },
          { key: 'status' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'applicant__1807' },
          { key: 'business_o_8703' },
          { key: 'issue_date' },
          { key: 'expire_date' },
          { key: 'fee_name' },
          { key: 'amount' },
          { key: 'vat' },
          { key: 'total' },
          { key: 'type' },
          { key: 'fathers_na_6391' },
          { key: 'mothers_na_4511' },
          { key: 'mobile_num_5699' },
          { key: 'national_i_6977' },
          { key: 'tin_number_7111' },
          { key: 'email_6489' },
          { key: 'division_5731' },
          { key: 'district_1865' },
          { key: 'upazilla__4303' },
          { key: 'present_ad_5362' },
          { key: 'division_7828' },
          { key: 'district_6214' },
          { key: 'upazilla_4339' },
          { key: 'permanent__5521' },
          { key: 'division_6431' },
          { key: 'district_6510' },
          { key: 'upazilla_2082' },
          { key: 'addresseng_3160' },
          { key: 'business_c_2676' },
          { key: 'name_of_no_2818' },
          { key: 'product_ve_4186' },
          { key: 'product_de_4058' },
          { key: 'challan_no_date' },
          { key: 'bank_name_and_branch' },
          { key: 'status' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    headerValue: function () {
        const headerVal = []
        const dateTo = this.$t('globalTrans.from_date') + ' : ' + (this.formData.from_date ? dateFormat(this.formData.from_date) : this.$t('globalTrans.all')) + '; '
        const dateFrom = this.$t('globalTrans.to_date') + ' : ' + (this.formData.to_date ? dateFormat(this.formData.to_date) : this.$t('globalTrans.all'))
        headerVal[7] = dateTo + dateFrom
        const amount = this.$t('globalTrans.amount') + ' : ' + this.$n(this.amount)
        headerVal[8] = amount
        if (this.$i18n.locale === 'en') {
          headerVal[0] = this.headerExcelDefault.orgName
          headerVal[1] = this.headerExcelDefault.address
          headerVal[2] = this.$t('li_step.dam_report')
          if (this.formData.division_id) {
            headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.formData.division_name_en
          } else {
            headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.$t('globalTrans.all')
          }
          if (this.formData.district_id) {
            headerVal[4] = this.$t('globalTrans.district') + ': ' + this.formData.district_name_en
          } else {
            headerVal[4] = this.$t('globalTrans.district') + ':  ' + this.$t('globalTrans.all')
          }
          if (this.formData.division_id) {
            headerVal[5] = this.$t('globalTrans.upazila') + ':  ' + this.formData.upazila_name_en
          } else {
            headerVal[5] = this.$t('globalTrans.upazila') + ':  ' + this.$t('globalTrans.all')
          }
          if (this.formData.market_id) {
            headerVal[6] = this.$t('crop_price_config.market') + ': ' + this.formData.market_name_en
          } else {
            headerVal[6] = this.$t('crop_price_config.market') + ':  ' + this.$t('globalTrans.all')
          }
        } else {
          headerVal[0] = this.headerExcelDefault.orgNameBn
          headerVal[1] = this.headerExcelDefault.address_bn
          headerVal[2] = this.$t('li_step.dam_report')
          if (this.formData.division_id) {
            headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.formData.division_name_bn
          } else {
            headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.$t('globalTrans.all')
          }
          if (this.formData.district_id) {
            headerVal[4] = this.$t('globalTrans.district') + ': ' + this.formData.district_name_bn
          } else {
            headerVal[4] = this.$t('globalTrans.district') + ':  ' + this.$t('globalTrans.all')
          }
          if (this.formData.upazila_id) {
            headerVal[5] = this.$t('globalTrans.upazila') + ':  ' + this.formData.upazila_name_bn
          } else {
            headerVal[5] = this.$t('globalTrans.upazila') + ':  ' + this.$t('globalTrans.all')
          }
          if (this.formData.market_id) {
            headerVal[6] = this.$t('crop_price_config.market') + ': ' + this.formData.market_name_bn
          } else {
            headerVal[6] = this.$t('crop_price_config.market') + ':  ' + this.$t('globalTrans.all')
          }
        }
        return headerVal
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    },
    priceEntryTypeList: function () {
      return this.$store.state.agriMarketing.commonObj.priceEntryTypeList
    },
    timePreiodList: function () {
      return this.$store.state.agriMarketing.commonObj.timePreiodList
    },
    i18 () {
      return this.$i18n.locale
    },
    priceTypeList: function () {
      return this.$store.state.agriMarketing.commonObj.priceTypeList
    },
    loading () {
        return this.$store.state.commonObj.loading
    },
    loggedUserPrivilege: function () {
      return this.$store.state.agriMarketing.commonObj.loggedUserPrivilege
    }
  },
  watch: {
    'formData.division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
          this.districtList = this.getDistrictList(newVal)
      }
    },
    'formData.district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
        this.marketList = this.getDisMarketList(newVal)
      }
    },
    'formData.upazila_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
          this.marketList = this.getMarketList(newVal)
      }
    }
  },
  methods: {
    challanCheck (data, type) {
      const challan = []
      const challanDate = []
      data.map((item, index) => {
        challan.push(item.challan_no)
        challan.push(item.challan_no_vat)
        challanDate.push(dateFormat(item.pay_date))
        challanDate.push(dateFormat(item.pay_date_vat))
      })
      if (type === 'challan') {
        return challan.toString()
      }
      return challanDate.join(', ')
    },
    bankBranchSet (data) {
      const bankList = []
      data.map((item, index) => {
        const bank = item.bank_name + '(' + item.branch_name + ')'
        bankList.push(bank)
      })
      return bankList.join(', ')
    },
    setOrgAndOffice () {
      if (this.$store.state.Auth.activeRoleId !== 1 && this.$store.state.Auth.authUser.is_org_admin !== 1) {
        this.formData.office_id = this.$store.state.Auth.authUser.office_id
        this.formData.division_id = this.$store.state.Auth.authUser.office_detail.division_id
        this.formData.district_id = this.$store.state.Auth.authUser.office_detail.district_id
      }
    },
    disabledCheck () {
      if (this.$store.state.Auth.activeRoleId !== 1 && this.$store.state.Auth.authUser.is_org_admin !== 1) {
        return true
      }
      return false
    },
    excelInputChange () {
      const labelColumn = {}
      const labelColumnBn = {}
      this.labelData.map((item, index) => {
        labelColumn[this.$t(item.labels, 'en')] = item.excelKey
        labelColumnBn[this.$t(item.labels, 'bn')] = item.excelKey
      })
      this.excelColumn = labelColumn
      this.excelColumnBn = labelColumnBn
    },
    dataCustomizeExcel () {
      try {
        if (typeof this.datas !== 'undefined') {
          const listData = this.datas
          let slNo = 1
          listData.map(item => {
            let typeValue = ''
            if (item.type === 1) {
              typeValue = this.$t('globalTrans.new')
            } else {
              typeValue = this.$t('globalTrans.renew')
            }
            if (this.$i18n.locale === 'bn') {
              item.index = this.$n(slNo++)
              item.ex_application_id = item.application_id
              item.ex_applicant__1807 = item.applicant__1497
              item.ex_business_name = item.business_o_1113
              item.ex_issue_date = item.issue_date ? dateFormat(item.issue_date) : null
              item.ex_expire_date = item.expire_date ? dateFormat(item.expire_date) : null
              item.ex_fee_name = this.getBusinessClass(item.business_c_2676)
              item.ex_amount = this.$n(item.amount)
              item.ex_vat = this.$n(item.vat)
              item.ex_total = this.$n(parseFloat(item.amount) + parseFloat(item.vat))
              item.ex_type = typeValue
              item.ex_fathers_na_6391 = item.fathers_na_6391
              item.ex_mothers_na_4511 = item.mothers_na_5069
              item.ex_mobile_num_5699 = this.mobileNoSet(item.mobile_num_5699, this.$i18n.locale)
              item.ex_national_i_6977 = this.nidNoSet(item.national_i_6977, this.$i18n.locale)
              item.ex_tin_number_7111 = item.tin_number_7111
              item.ex_email_6489 = item.email_6489
              item.ex_division_5731 = this.getDivision(item.division_5731)
              item.ex_district_1865 = this.getDistrict(item.district_1865)
              item.ex_upazilla__4303 = this.getUpazila(item.upazilla__4303)
              item.ex_present_ad_5362 = item.present_ad_5848
              item.ex_division_7828 = this.getDivision(item.division_7828)
              item.ex_district_6214 = this.getDistrict(item.district_6214)
              item.ex_upazilla_4339 = this.getUpazila(item.upazilla_4339)
              item.ex_permanent__5521 = item.permanent__7835
              item.ex_division_6431 = this.getDivision(item.division_6431)
              item.ex_district_6510 = this.getDistrict(item.district_6510)
              item.ex_upazilla_2082 = this.getUpazila(item.upazilla_2082)
              item.ex_addresseng_3160 = item.addressban_1958
              item.ex_business_c_2676 = this.getBusinessClass(item.business_c_2676)
              item.ex_name_of_no_2818 = this.getMarketDirectory(item.name_of_no_2818)
              item.ex_product_ve_4186 = this.getProductType(item.product_ve_4186)
              item.ex_product_de_4058 = this.getProduct(item.product_de_4058)
              item.challan_no_date = this.challanCheck(item.payment, 'challan') + '\n' + this.challanCheck(item.payment, 'date')
              item.bank_name_and_branch = this.bankBranchSet(item.payment, 'challan')
              item.ex_status = item.addressban_1958
            } else {
              item.index = this.$n(slNo++)
              item.ex_application_id = item.application_id
              item.ex_applicant__1807 = item.applicant__1807
              item.ex_business_name = item.business_o_8703
              item.ex_issue_date = item.issue_date ? dateFormat(item.issue_date) : null
              item.ex_expire_date = item.expire_date ? dateFormat(item.expire_date) : null
              item.ex_fee_name = this.getBusinessClass(item.business_c_2676)
              item.ex_amount = this.$n(item.amount)
              item.ex_vat = this.$n(item.vat)
              item.ex_total = this.$n(parseFloat(item.amount) + parseFloat(item.vat))
              item.ex_type = typeValue
              item.ex_fathers_na_6391 = item.fathers_na_9299
              item.ex_mothers_na_4511 = item.mothers_na_4511
              item.ex_mobile_num_5699 = this.mobileNoSet(item.mobile_num_5699, this.$i18n.locale)
              item.ex_national_i_6977 = this.nidNoSet(item.national_i_6977, this.$i18n.locale)
              item.ex_tin_number_7111 = item.tin_number_7111
              item.ex_email_6489 = item.email_6489
              item.ex_division_5731 = this.getDivision(item.division_5731)
              item.ex_district_1865 = this.getDistrict(item.district_1865)
              item.ex_upazilla__4303 = this.getUpazila(item.upazilla__4303)
              item.ex_present_ad_5362 = item.present_ad_5362
              item.ex_division_7828 = this.getDivision(item.division_7828)
              item.ex_district_6214 = this.getDistrict(item.district_6214)
              item.ex_upazilla_4339 = this.getUpazila(item.upazilla_4339)
              item.ex_permanent__5521 = item.permanent__5521
              item.ex_division_6431 = this.getDivision(item.division_6431)
              item.ex_district_6510 = this.getDistrict(item.district_6510)
              item.ex_upazilla_2082 = this.getUpazila(item.upazilla_2082)
              item.ex_addresseng_3160 = item.addresseng_3160
              item.ex_business_c_2676 = this.getBusinessClass(item.business_c_2676)
              item.ex_name_of_no_2818 = this.getMarketDirectory(item.name_of_no_2818)
              item.ex_product_ve_4186 = this.getProductType(item.product_ve_4186)
              item.ex_product_de_4058 = this.getProduct(item.product_de_4058)
              item.challan_no_date = this.challanCheck(item.payment, 'challan') + '\n' + this.challanCheck(item.payment, 'date')
              item.bank_name_and_branch = this.bankBranchSet(item.payment, 'challan')
              item.ex_status = item.addressban_1958
            }
            return Object.assign({}, item)
          })
          return listData
        } else {
          return []
        }
      } catch (e) {
        return []
      }
    },
    pdfExport () {
      const reportTitle = this.$t('li_step.dam_report')
      ExportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail', 13, reportTitle, this.datas, this, this.formData, this.labelData)
    },
    onChangeFile (e) {
      this.attachmentDemo = e.target.files[0]
    },
    getEditingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    configData () {
      RestApi.getData(licenseRegistrationServiceBaseUrl, damReportConf).then(response => {
        if (response.success) {
          this.businessClass = response.data
        }
      })
    },
    headerDataExcel () {
      RestApi.getData(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail/13').then(response => {
      if (response.success) {
        const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 13)
        const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
        const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
        this.headerExcelDefault.orgName = orgName
        this.headerExcelDefault.orgNameBn = orgNameBn
        this.headerExcelDefault.address = response.data.address
        this.headerExcelDefault.address_bn = response.data.address_bn
      }
    })
    },
    async saveData () {
      Store.commit('mutateCommonProperties', {
          loading: true
      })
      const divisionObj = this.$store.state.commonObj.divisionList.find(item => item.value === this.formData.division_id)
      this.formData.division_name_en = typeof divisionObj !== 'undefined' ? divisionObj.text_en : ''
      this.formData.division_name_bn = typeof divisionObj !== 'undefined' ? divisionObj.text_bn : ''
      const districtListObj = this.$store.state.commonObj.districtList.find(item => item.value === this.formData.district_id)
      this.formData.district_name_en = typeof districtListObj !== 'undefined' ? districtListObj.text_en : ''
      this.formData.district_name_bn = typeof districtListObj !== 'undefined' ? districtListObj.text_bn : ''
      const upazilaListObj = this.$store.state.commonObj.upazilaList.find(item => item.value === this.formData.upazila_id)
      this.formData.upazila_name_en = typeof upazilaListObj !== 'undefined' ? upazilaListObj.text_en : ''
      this.formData.upazila_name_bn = typeof upazilaListObj !== 'undefined' ? upazilaListObj.text_bn : ''
      const marketListObj = this.$store.state.licenseRegistration.commonObj.marketList.find(item => item.value === this.formData.market_id)
      this.formData.market_name_en = typeof marketListObj !== 'undefined' ? marketListObj.text_en : ''
      this.formData.market_name_bn = typeof marketListObj !== 'undefined' ? marketListObj.text_bn : ''
      const businessObj = this.businessClass.find(item => item.type_name === this.formData.business_class)
      this.formData.business_name_en = typeof businessObj !== 'undefined' ? businessObj.type_name : ''
      this.formData.business_name_bn = typeof businessObj !== 'undefined' ? businessObj.type_name_bn : ''
      if (this.formData.business_class) {
        this.formData.business_class_key = this.businessClass.findIndex(p => p.type_name === this.formData.business_class)
      } else {
        this.formData.business_class_key = null
      }
      await RestApi.getData(licenseRegistrationServiceBaseUrl, damReport, this.formData).then(response => {
        if (response.success) {
          Store.commit('mutateCommonProperties', {
            loading: false
          })
          if (response.data) {
            this.amount = response.total
            this.datas = this.formatData(response.data)
            this.firstTime = false
          }
        } else {
          this.datas = []
          Store.commit('mutateCommonProperties', {
            loading: false
          })
          this.firstTime = false
        }
      })
    },
    formatData (data) {
      const formattedData = data.map(item => {
        const divisionData = this.$store.state.commonObj.divisionList.find(division => division.value === item.division_id)
        const districtData = this.$store.state.commonObj.districtList.find(district => district.value === item.district_id)
        const upazilaData = this.$store.state.commonObj.upazilaList.find(upazilla => upazilla.value === item.upazila_id)
        const tmpBindingData = {
          division_name: divisionData !== undefined ? divisionData.text_en : '',
          division_name_bn: divisionData !== undefined ? divisionData.text_bn : '',
          distict_name: districtData !== undefined ? districtData.text_en : '',
          distict_name_bn: districtData !== undefined ? districtData.text_bn : '',
          upazila_name: upazilaData !== undefined ? upazilaData.text_en : '',
          upazila_name_bn: upazilaData !== undefined ? upazilaData.text_bn : ''
        }
        return Object.assign({}, item, tmpBindingData)
      })
      return formattedData
    },
    getDistrictList (divisionId = null) {
      return this.$store.state.commonObj.districtList.filter(item => item.division_id === divisionId)
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList
      if (districtId) {
          return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getDisMarketList (districtId = null) {
      const dataList = this.$store.state.licenseRegistration.commonObj.marketList.filter(item => item.district_id === districtId)
      return dataList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    getDivision (divisionId = null) {
      const dataList = this.$store.state.commonObj.divisionList.find(item => item.value === divisionId)
      if (this.$i18n.locale === 'bn') {
        return dataList !== undefined ? dataList.text_bn : ''
      } else {
        return dataList !== undefined ? dataList.text_en : ''
      }
    },
    getDistrict (districtId = null) {
      const dataList = this.$store.state.commonObj.districtList.find(item => item.value === districtId)
      if (this.$i18n.locale === 'bn') {
        return dataList !== undefined ? dataList.text_bn : ''
      } else {
        return dataList !== undefined ? dataList.text_en : ''
      }
    },
    getUpazila (upazilaId = null) {
      const dataList = this.$store.state.commonObj.upazilaList.find(item => item.value === upazilaId)
      if (this.$i18n.locale === 'bn') {
        return dataList !== undefined ? dataList.text_bn : ''
      } else {
        return dataList !== undefined ? dataList.text_en : ''
      }
    },
    getMarketDirectory (marketId = null) {
      const dataList = this.$store.state.licenseRegistration.commonObj.marketList.find(item => item.value === marketId)
      if (this.$i18n.locale === 'bn') {
        return dataList !== undefined ? dataList.text_bn : ''
      } else {
        return dataList !== undefined ? dataList.text_en : ''
      }
    },
    getBusinessClass (businessClass) {
      const arr = []
        JSON.parse(businessClass).forEach(item => {
          this.businessClass.map((businessClassNames, index) => {
            if (item === index) {
              const businessClassName = this.$i18n.locale === 'en' ? ' ' + businessClassNames.type_name : ' ' + businessClassNames.type_name_bn
              arr.push(businessClassName)
            }
          })
        })
      return arr.toString()
    },
    getProductType (productTypeIds) {
      const arr = []
        JSON.parse(productTypeIds).forEach(item => {
          const product = this.$store.state.licenseRegistration.commonObj.agricultureProductTypeList.find(data => data.value === item)
          const productName = this.$i18n.locale === 'en' ? ' ' + product.text_en : ' ' + product.text_bn
          arr.push(productName)
        })
      return arr.toString()
    },
    getProduct (productIds) {
      const arr = []
        JSON.parse(productIds).forEach(item => {
          const product = this.$store.state.licenseRegistration.commonObj.agricultureProductList.find(data => data.value === item)
          const productName = this.$i18n.locale === 'en' ? ' ' + product.text_en : ' ' + product.text_bn
          arr.push(productName)
        })
      return arr.toString()
    },
    getMarketList (upazillaId = null) {
      const dataList = this.$store.state.licenseRegistration.commonObj.marketList.filter(item => item.upazila_id === upazillaId)
      return dataList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    getPriceTypeList: function (entryType) {
      const dataList = this.$store.state.agriMarketing.commonObj.priceTypeList.filter(item => item.entry_type === entryType)
      return dataList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text }
        }
      })
    },
    mobileNoSet (data, local) {
      const myArray = data.split(',')
      const dataVal = []
      if (myArray) {
        myArray.forEach((item) => {
          let dataFind = []
            dataFind = local === 'bn' ? '০' + this.$n(item, { useGrouping: false }) : '0' + this.$n(item, { useGrouping: false })
          dataVal.push(dataFind)
        })
      }
      return dataVal.toString()
    },
    actions (serviceId, stepId) {
      const actions = this.$store.state.licenseRegistration.commonObj.seviceStepWiseButtonList.find(item => item.step_id === stepId && item.service_id === serviceId)
      if (typeof actions === 'undefined') {
        return {}
      } else {
        return actions
      }
    },
    ServiceLastStepCheck (serviceId, stepId, status, generateId, docVerify, type) {
      // const tmpStepList = this.$store.state.licenseRegistration.commonObj.serviceSteps.filter(item => item.service_id === parseInt(serviceId))
      // const tmpIndVStepList = tmpStepList.find(item => item.step_id === parseInt(stepId))
      // const order = tmpIndVStepList.order
      // const orderCheck = tmpStepList.find(item => item.order === order + 1)
      const actions = JSON.parse(this.actions(serviceId, stepId).actions)
      if (type === 2 && status === 1) {
        return this.$t('globalTrans.completed')
      } else if (status === 0 && !docVerify) {
        return this.$t('globalTrans.processing')
      } else if (status === 2) {
        return this.$t('globalTrans.rejected')
      } else if (status === 7) {
        return this.$t('externalLrcpn.application_resubmit')
      } else if (status === 8) {
        return this.$t('externalLrcpn.transfered_to_nothi')
      } else if (actions.includes(4)) {
        if (status === 1) {
          return this.$t('globalTrans.completed')
        } else {
          return this.$t('globalTrans.processing')
        }
      } else if (actions.includes(6)) {
        if (docVerify === 1) {
          return this.$t('globalTrans.completed')
        } else {
          return this.$t('globalTrans.processing')
        }
      } else if (actions.includes(12)) {
        if (generateId) {
          return this.$t('globalTrans.completed')
        } else {
          return this.$t('globalTrans.processing')
        }
      } else if (actions.includes(14)) {
        if (generateId && status === 1) {
          return this.$t('globalTrans.completed')
        } else {
          return this.$t('globalTrans.processing')
        }
      } else {
        return this.$t('globalTrans.completed')
      }
    },
    getStepName (stId) {
      const step = this.$store.state.licenseRegistration.commonObj.stepNamesList.find(item => item.value === stId)
      return step !== undefined ? step.text : ''
    },
    nidNoSet (data, local) {
      const myArray = data.split(',')
      const dataVal = []
      if (myArray) {
        myArray.forEach((item) => {
          let dataFind = []
            dataFind = this.$n(item, { useGrouping: false })
          dataVal.push(dataFind)
        })
      }
      return dataVal.toString()
    }
  }
}
</script>
