import i18n from '@/i18n'
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import ReportHeading from '@/Utils/report-head-lic-o'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, newDatas, vm, search, labelData) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
        pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = [
        {
          columns: reportHeadData.reportHeadColumn, style: 'main_head'
        },
        { text: reportHeadData.address, style: 'address', alignment: 'center' }
      ]
      pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
      pdfContent.push({ text: '', style: 'fertilizer' })
      const allRowsHead = []
      const rowItem = [
        { text: vm.$t('globalTrans.division') + ' : ' + (vm.formData.division_id ? (i18n.locale === 'en' ? vm.formData.division_name_en : vm.formData.division_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
        { text: vm.$t('globalTrans.district') + ' : ' + (vm.formData.district_id ? (i18n.locale === 'en' ? vm.formData.district_name_en : vm.formData.district_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
        { text: vm.$t('globalTrans.upazila') + ' : ' + (vm.formData.upazila_id ? (i18n.locale === 'en' ? vm.formData.upazila_name_en : vm.formData.upazila_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
        { text: vm.$t('crop_price_config.market') + ' : ' + (vm.formData.market_id ? vm.getMarketDirectory(vm.formData.market_id) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
        { text: vm.$t('li_step.business_class') + ' : ' + (vm.formData.business_class ? (i18n.locale === 'en' ? vm.formData.business_name_en : vm.formData.business_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true }
      ]
      allRowsHead.push(rowItem)
      const rowItem1 = [
        { text: vm.$t('globalTrans.type') + ' : ' + (vm.formData.type ? (vm.formData.type === 1 ? vm.$t('globalTrans.new') : vm.$t('globalTrans.renew')) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
        { text: vm.$t('globalTrans.from_date') + ' : ' + (vm.formData.from_date ? dateFormat(vm.formData.from_date) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
        { text: vm.$t('globalTrans.to_date') + ' : ' + (vm.formData.to_date ? dateFormat(vm.formData.to_date) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
        { text: vm.$t('globalTrans.amount') + ' : ' + vm.$n(vm.amount), alignment: 'left', style: 'th', bold: true },
        {}
      ]
      allRowsHead.push(rowItem1)
      if (allRowsHead.length > 0) {
        pdfContent.push({
          table: {
            widths: ['20%', '20%', '20%', '20%', '20%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
      }
      pdfContent.push({ text: '', style: 'fertilizer' })
      const labelDatas = labelData.filter(item => item.show === '1').slice(0, 7)
      const headRows = []
      const width = []
      labelDatas.forEach((info, index) => {
        headRows.push(
          { text: vm.$t(info.labels, i18n.locale), style: 'th', alignment: 'center', bold: true }
        )
        if (index === 0) {
          width.push('10%')
        } else if (index === 1) {
          width.push('15%')
        } else if (index === 2) {
          width.push('15%')
        } else if (index === 3) {
          width.push('15%')
        } else if (index === 4) {
          width.push('15%')
        } else if (index === 5) {
          width.push('15%')
        } else if (index === 6) {
          width.push('15%')
        } else if (index === 7) {
          width.push('15%')
        } else if (index === 8) {
          width.push('15%')
        } else if (index === 9) {
          width.push('15%')
        } else {
          width.push('*')
        }
      })
      const listData = newDatas.map((item, index) => {
        const rowData = labelDatas.map((keyItem) => {
            if (keyItem.key === 'index') {
              return { text: vm.$n(index + 1), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'amount') {
              return { text: vm.$n(item.amount), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'vat') {
              return { text: vm.$n(item.vat), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'total') {
              return { text: vm.$n(parseInt(item.vat) + parseInt(item.amount)), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'issue_date') {
              let issueDate = ''
              if (item.issue_date) {
                issueDate = item.issue_date
              }
              return { text: issueDate, style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'expire_date') {
              let expireDate = ''
              if (item.expire_date) {
                expireDate = item.expire_date
              }
              return { text: expireDate, style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'type') {
              let typeView = ''
              if (item.type === 1) {
                typeView = vm.$t('globalTrans.new')
              } else {
                typeView = vm.$t('globalTrans.renew')
              }
              return { text: typeView, style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'division_5731') {
              return { text: vm.getDivision(item.division_5731), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'district_1865') {
              return { text: vm.getDistrict(item.district_1865), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'upazilla__4303') {
              return { text: vm.getUpazila(item.upazilla__4303), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'division_7828') {
              return { text: vm.getDivision(item.division_7828), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'district_6214') {
              return { text: vm.getDistrict(item.district_6214), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'upazilla_4339') {
              return { text: vm.getUpazila(item.upazilla_4339), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'division_6431') {
              return { text: vm.getDivision(item.division_6431), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'district_6510') {
              return { text: vm.getDistrict(item.district_6510), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'upazilla_2082') {
              return { text: vm.getUpazila(item.upazilla_2082), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'business_c_2676') {
              return { text: vm.getBusinessClass(item.business_c_2676), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'fee_name') {
              return { text: vm.getBusinessClass(item.business_c_2676), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'name_of_no_2818') {
              return { text: vm.getMarketDirectory(item.name_of_no_2818), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'product_de_4058') {
              return { text: vm.getProduct(item.product_de_4058), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'product_ve_4186') {
              return { text: vm.getProductType(item.product_ve_4186), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'mobile_num_5699') {
              return { text: vm.mobileNoSet(item.mobile_num_5699, i18n.locale), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'national_i_6977') {
              return { text: vm.nidNoSet(item.national_i_6977, i18n.locale), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'challan_no_date') {
              return { text: vm.challanCheck(item.payment, 'challan') + '\n' + vm.challanCheck(item.payment, 'date'), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'bank_name_and_branch') {
              return { text: vm.bankBranchSet(item.payment, 'challan'), style: 'td', alignment: 'center' }
            }
            return { text: item[keyItem.key], style: 'td', alignment: 'center' }
        })
        return rowData
      })
      listData.unshift(headRows)
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: width,
          body: listData
        }
      })
      // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'landscape',
      // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
      styles: {
        th: {
          fontSize: (i18n.locale === 'bn') ? 10 : 9,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: (i18n.locale === 'bn') ? 10 : 9,
          margin: [3, 3, 3, 3]
        },
        search: {
          fontSize: (i18n.locale === 'bn') ? 10 : 8,
          margin: [3, 3, 3, 3]
        },
        fertilizer: {
          margin: [5, 0, 0, 5]
        },
        header: {
          fontSize: 12,
          margin: [0, 0, 0, 4]
        },
        header2: {
          fontSize: 14,
          margin: [0, 10, 0, 20]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 5]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        krishi: {
          margin: [0, -5, 0, 15],
          alignment: 'center'
        },
        header3: {
          fontSize: 9,
          margin: [0, 0, 0, 0]
        },
        address: {
          fontSize: 9,
          margin: [0, -10, 0, 0]
        },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).download('dam-report')
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
